import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ccMask',
  standalone: true
})
export class CcMaskPipe implements PipeTransform {
  transform(value: string | undefined, options: { length?: number, prefix?: string } = { length: 4 }): any {
    if (!value) {
      return '';
    }
    const length = options.length || 4;
    if (value && value.length > length) {
      value = value.slice(-4);
    }

    if (options.prefix) {
      value = options.prefix + value;
    }
    return value;
  }
}
